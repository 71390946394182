// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-complaints-policy-js": () => import("./../../../src/pages/complaints-policy.js" /* webpackChunkName: "component---src-pages-complaints-policy-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-continuing-professional-development-policy-js": () => import("./../../../src/pages/continuing-professional-development-policy.js" /* webpackChunkName: "component---src-pages-continuing-professional-development-policy-js" */),
  "component---src-pages-education-js": () => import("./../../../src/pages/education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-financial-services-guide-js": () => import("./../../../src/pages/financial-services-guide.js" /* webpackChunkName: "component---src-pages-financial-services-guide-js" */),
  "component---src-pages-how-we-do-it-js": () => import("./../../../src/pages/how-we-do-it.js" /* webpackChunkName: "component---src-pages-how-we-do-it-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insight-js": () => import("./../../../src/pages/insight.js" /* webpackChunkName: "component---src-pages-insight-js" */),
  "component---src-pages-privacy-credit-js": () => import("./../../../src/pages/privacy-credit.js" /* webpackChunkName: "component---src-pages-privacy-credit-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-risk-profile-js": () => import("./../../../src/pages/risk-profile.js" /* webpackChunkName: "component---src-pages-risk-profile-js" */),
  "component---src-pages-service-packages-js": () => import("./../../../src/pages/service-packages.js" /* webpackChunkName: "component---src-pages-service-packages-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-what-we-do-js": () => import("./../../../src/pages/what-we-do.js" /* webpackChunkName: "component---src-pages-what-we-do-js" */),
  "component---src-pages-wholesale-js": () => import("./../../../src/pages/wholesale.js" /* webpackChunkName: "component---src-pages-wholesale-js" */),
  "component---src-templates-education-js": () => import("./../../../src/templates/education.js" /* webpackChunkName: "component---src-templates-education-js" */),
  "component---src-templates-insight-js": () => import("./../../../src/templates/insight.js" /* webpackChunkName: "component---src-templates-insight-js" */)
}

